/*
 * JavaScript de view de edição de usuários.
 */

import { DependentSelect } from '../../../../javascripts/components/dependent-select/dependent-select'
import 'select2';

$(function(){

  const _domPricingContainer = $('[data-input=pricing_container]'),
        _domPricingContent = $('[data-input=pricing_content]'),
        _domCitySelect = _domPricingContainer.find('[data-input=city_code]'),
        _cachedData = {}

  // event handlers

  _domPricingContainer.on('change', (aEvent) => {
    _updatePricingContent(aEvent.target)
  })

  // privates

  function _updatePricingContent() {
    const selected = _domCitySelect.val(),
          params = { city_code: selected },
          paramsData = new URLSearchParams(params).toString(),
          url = _domPricingContainer.data('url')

    if (_cachedData[paramsData]) {
      _setPricingContent(_cachedData[paramsData])
    } else {

      _domPricingContainer.attr('data-loading', 'true')

      Rails.ajax({
        url: url,
        data: paramsData,
        type: 'GET',
        success: function(data, status, xhr) {

          _cachedData[paramsData] = xhr.responseText

          _setPricingContent(_cachedData[paramsData])
        },

        error: function() {

        },

        complete: function(data) {
          _domPricingContainer.attr('data-loading', 'false')
        }
      })
    }
  }

  function _setPricingContent(aContent) {
    _domPricingContent.html(aContent)
  }

  function _initSelect2() {
    _domPricingContainer.find('select.ignore-select2').select2()
    _domPricingContainer.find('select.ignore-select2 + .select2-container').css('width', '100%')

    $('[data-toggle=dependent-select]').each(function() {

      var mainContainer = $(this),
          dependentSelect = new DependentSelect(mainContainer);
    });
  }

  function _init() {
    _initSelect2()
  }

  _init()
})
